<template>
  <div>
    <font-awesome-icon
      :id="'tooltip-target-' + label"
      icon="edit"
      class="edit-icon-commercial-taux"
      @click.prevent.stop="$refs['updtaeTaux' + label].show()"
    />

    <b-tooltip :target="'tooltip-target-' + label" triggers="hover">
      Modifier coef {{ label }}
    </b-tooltip>
    <!-- UPDATE TAUX MODAL -->
    <b-modal
      :ref="'updtaeTaux' + label"
      :id="'updtaeTaux' + label"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Modification ' + label"
      @hidden="hideModal('updtaeTaux' + label)"
      modal-class="modal-update-taux-frais"
    >
      <b-form-group
        id="fieldset-horizontal-type"
        label-cols-sm="7"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="9"
        label="Coef  : "
        label-for="type-modal"
      >
        <b-form-input
          id="fieldset-horizontal-type"
          v-model="taux"
          required
          autocomplete="off"
          class="b-form-select-raduis"
        ></b-form-input>
      </b-form-group>
      <div class="form-modal-custom-style mt-2">
        <div class="messageError">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style"
            @click.prevent.stop="handleChangeTaux"
          >
            <span>
              Modifier
              <div v-if="loading" class="loading ml-2">
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END PDATE TAUX MODAL  -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    label: { required: true },
    column: { required: true },
    dataToUse: { required: true },
    updateFunction: { required: true }
  },
  data() {
    return {
      error: null,
      loading: false,
      taux: null
    };
  },
  methods: {
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.taux = null;
      this.error = null;
      this.loading = false;
    },
    async handleChangeTaux() {
      if (this.taux != null) {
        this.erreur = null;
        this.loading = true;
        var bodyFormData = new FormData();
        bodyFormData.append('column', this.column);
        bodyFormData.append('value', this.taux);
        let indexToUse = 0;
        for (let j = 0; j < this.dataToUse.length; j++) {
          for (let i = 0; i < this.dataToUse[j].lots.length; i++) {
            for (
              let p = 0;
              p < this.dataToUse[j].lots[i].projects.length;
              p++
            ) {
              if (this.dataToUse[j].lots[i].projects[p].check == true) {
                bodyFormData.append(
                  'ids[' + [indexToUse] + ']',
                  this.dataToUse[j].lots[i].projects[p].id
                );
                indexToUse = indexToUse + 1;
              }
            }
          }
        }
        const response = await this.updateFunction(bodyFormData);
        if (response) {
          this.$emit('reloadData');
          this.hideModal('updtaeTaux' + this.label);
        } else {
          this.error = response.error;
          this.loading = false;
        }
      } else {
        this.error = 'Variable taux obligatoire.';
      }
    }
  },
  computed: {}
};
</script>

<style scoped lang="scss">
.edit-icon-commercial-taux {
  font-size: 11.5px;
  &:hover {
    color: #4d4bac;
  }
}
</style>
<style lang="scss">
.modal-update-taux-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
</style>
